<template>
  <div class="page-dashboard">
    <v-container>
      <v-row>
        <!-- statistic section -->
        <!-- Circle statistic -->
        <v-col
          cols="3"
          v-for="(item, index) in trending"
          :key="'c-trending' + index"
        >
          <circle-statistic
            :title="item.subheading"
            :sub-title="item.headline"
            :caption="item.caption"
            :icon="item.icon.label"
            :color="item.linear.color"
            :value="item.linear.value"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { getDashboard } from '@/api/dashboard'
import Material from 'vuetify/es5/util/colors'
import CircleStatistic from '@/components/widgets/statistic/CircleStatistic'

// import { mapGetters } from 'vuex'
export default {
  name: 'PageDashboard',
  components: {
    CircleStatistic
  },
  data: () => ({
    color: Material,
    selectedTab: 'tab-1',
    loading: false,
    trending: [
      {
        subheading: '',
        headline: '',
        caption: '',
        percent: 0,
        icon: {
          // label: 'ทำแบบประเมิน',
          color: 'info'
        },
        linear: {
          value: 0,
          color: 'info'
        }
      }
    ]
  }),
  beforeCreate() {
    console.log('beforeCreate')
  },
  created() {
    this.loading = true
    getDashboard()
      .then((dashboard) => {
        this.trending = dashboard.map((item) => {
          return {
            subheading: `${item.title}`,
            headline: '',
            caption: `ทั้งหมด ${item.total} ทำแล้ว ${item.surveyed} สำเร็จ ${item.successed}`,
            percent: 0,
            icon: {
              // label: 'ทำแบบประเมิน',
              color: 'info'
            },
            linear: {
              value: `${item.total}/${item.surveyed}`,
              color: 'info'
            }
          }
        })
        // this.trending[0].caption = `${dashboard.assessment.title} (${dashboard.assessment.total})`
        // this.trending[0].linear.value = dashboard.assessment.surveyed
      })
      .finally(() => {
        this.loading = false
      })
  },
  computed: {
    // ...mapGetters(['isLogin'])
  },
  mounted() {
    console.log('mounted')
    // if (!this.isLogin()) {
    //   this.$route.push('/auth/login')
    //   return
    // }
  }
}
</script>
